@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

// Specific variables and settings for the theme
@import 'components/elements';

// We target the mediascreens here, so you don't have to worry about them in each sass file.
//Small target's every screen (default)
@media screen {
	@import "components/small";
}
// 640px up
@media screen and #{breakpoint(medium)} {
	@import "components/medium";
}
// 1024px up
@media screen and #{breakpoint(large)} {
	@import "components/large";
}
// 1440px up
@media screen and #{breakpoint(xlarge)} {
	@import "components/xlarge";
}
// 1920px up
@media screen and #{breakpoint(xxlarge)} {
	@import "components/xxlarge";
}
