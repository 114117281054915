//Topbar and menu
#container.js-document-closed {
	transform: translateX(0px);
	transition: transform 700ms cubic-bezier(0.91, 0.01, 0.6, 0.99); }

#container.js-document-opened {
	transform: translateX(100%);
	transition: transform 700ms cubic-bezier(0.91, 0.01, 0.6, 0.99); }

.menu-wrapper-open {
	.container {
		transform: translateX(0%);
		transition: transform 700ms cubic-bezier(0.82, 0.01, 0.77, 0.78); } }

.hamburger.open {
	background-color: transparent;
	transition: background-color 300ms linear; }

.menu-wrapper-closed {
	.container {
		transform: translateX(-50%);
		transition: transform 700ms cubic-bezier(0.82, 0.01, 0.77, 0.78); } }

.hamburger.closed {
	transition: background-color 300ms linear, transform 400ms ease-in-out; }

.page-wrapper {
	display: block;
	position: relative;
	overflow: hidden; }

#menu-wrapper {
	position: fixed;
	width: 100%;
	padding: 0;
	z-index: 0;
	top: 0;
	bottom: 0;
	background: url('../img/menu.jpg'), $dark-gray;
	background-position: center left;
	background-size: contain;
	background-repeat: no-repeat;

	.container {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 50%;
		padding: 90px 20px 20px 15px;

		.navbar-nav {
			position: relative;
			width: 100%;
			margin: 0px;
			padding: 0px;
			z-index: 99;
			text-align: right;

			li {
				width: 100%;
				display: block;
				position: relative;
				padding: 2px 0px 2px;

				a {
					font-size: 1.5em;
					text-decoration: none;
					color: darken($white, 30);
					font-family: "minion-pro",serif;
					transform: translateX(0px);
					transition: color 300ms linear;

					&:hover {
						color: $white;
						transition: color 300ms linear; } } } }

		.dropdown-menu {
			margin: 0 1.25rem 0 0; } } }

.menu-wrapper {
	transform: translateX(0px);

	.menu-links {
		.menu {
			li {
				opacity: 1;
				transform: translateX(0px); } } } }

#topbar {
	position: fixed;
	width: 100%;
	height: 70px;
	z-index: 999;
	top: 0px;
	transition: background-color 350ms ease, transform 350ms ease;

	.logo {
		position: absolute;
		left: 15px;
		top: 13px;

		img {
			max-height: 40px; } }

	.hamburger {
		display: block;
		position: absolute;
		padding: 15px;
		top: 13px;
		right: 0px;
		z-index: 99999;

		#nav-icon {
			width: 40px;
			height: 14px;
			position: relative;
			margin-right: 0px;
			margin-left: auto;
			transform: rotate(0deg);
			transition: transform .5s ease-in-out;
			cursor: pointer; }

		#nav-icon span {
			display: block;
			position: absolute;
			height: 2px;
			width: 100%;
			background-color: black;
			border-radius: 0px;
			opacity: 1;
			left: 0; }

		#nav-icon span:nth-child(1) {
			top: 0px;
			transform-origin: 50%, 50%;
			transition: top 150ms linear 250ms, transform 250ms ease 0ms, background-color 350ms ease 600ms; }


		#nav-icon span:nth-child(2) {
			top: 7px;
			width: 100%;
			margin: 0px 0% 0px;
			transition: background-color 350ms ease 600ms; }

		#nav-icon span:nth-child(3) {
			top: 14px;
			transform-origin: 50%, 50%;
			transition: top 150ms linear 250ms, transform 250ms ease 0ms, background-color 350ms ease 600ms; }

		&.open {
			#nav-icon span {
				background-color: $white; }

			#nav-icon span:nth-child(1) {
				transform: rotate(45deg);
				top: 7px;
				transition: top 150ms linear, transform 250ms ease 150ms, background-color 250ms ease 500ms; }

			#nav-icon span:nth-child(2) {
				width: 0%;
				margin: 0px 50% 0px;
				transition: width 250ms ease-in-out, margin 250ms ease-in-out, background-color 250ms ease 500ms; }

			#nav-icon span:nth-child(3) {
				transform: rotate(-45deg);
				top: 7px;
				transition: top 150ms linear, transform 250ms ease 150ms, background-color 250ms ease 500ms; } } } }

.topbar, .topbar-closed {
	li {
		opacity: 0;
		transform: scale(0.4);
		visibility: hidden;
		transition: transform 300ms ease 0ms, opacity 300ms ease 0ms, visibility 300ms ease 0ms, background-color 300ms ease; }

	.logo {
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
		transition: opacity 250ms ease 250ms, visibility 250ms ease 250ms; } }

.topbar-open {
	background-color: transparent;
	transition: background-color 250ms ease;

	.logo {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition: opacity 250ms ease 0ms, visibility 250ms ease 0ms; }

	&.topbar.headroom--not-top {
		background-color: transparent;
		box-shadow: none; } }

.topbar.headroom--not-top {
	background-color: $white;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); }

.topbar.headroom--unpinned {
	transform: translateY(-100%); }

.topbar.headroom--pinned {
	transform: translateY(0%); }
