//Override menu for each media uqery
#menu-wrapper {
  background: url('../img/menu.jpg'), $dark-gray;
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 1.25em; }

//---------------------------First Section--------------------------------------
.front-page-capa {
  height: 80vh; }

//-----------------------Bloquote Section---------------------------------------
.front-page-blockquote {
  padding-top: 25em;
  padding-bottom: 7em;

  blockquote {
    font-size: 2em; } }

//--------------------------Notice Page-----------------------------------------
.page-header {
  .image {
    height: 70vh; } }

.page-content {
  .notice-content {
    p {
      column-count: 3; } } }


//--------------------------Footer----------------------------------------------
footer {
  .menu-site-map {
    display: block;
    color: $white;
    padding: 1.5em 0;

    .footer-menu {
      margin: 0;
      li {
        height: 25px;
        text-decoration: none;
        list-style: none;
        a {
          font-weight: 400;
          color: rgba($white, 0.5);
          transition: all 500ms ease;
          &:hover {
            color: $white; } } }

      .footer-sub-menu {
        margin-left: 1rem;
        li {
          height: 25px;
          text-decoration: none;
          list-style: none;
          a {
            font-weight: 400;
            color: rgba($white, 0.5);
            transition: all 500ms ease;
            &:hover {
              color: $white; } } } } }

    .social {
      margin: 1em 0 0 0;

      li {
        float: left;
        height: 25px;
        list-style: none;
        .icon {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          float: left;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat; }
        a {
          float: left;
          height: 20px;
          line-height: 20px;
          font-weight: 400;
          color: rgba($white, 0.5);
          transition: all 500ms ease;
          &:hover {
            color: $white; } } }

      .facebook {
        background-image: url('../img/icons/facebook.svg'); }

      .twitter {
        background-image: url('../img/icons/twitter.svg'); } } }

  .bottom-site-map {

    .footer-logo {
      margin: 0;
      float: left; }

    .footer-more-links {
      display: block;
      float: right;
      height: 54px;

      .copyright {
        float: left;
        line-height: 54px;
        text-transform: uppercase;
        color: rgba($white, 0.5); }
      .menu {
        height: 54px;
        float: left;
        li {
          height: 54px;
          a {
            color: rgba($white, 0.5);
            transition: all 500ms ease;
            &:hover {
              color: $white; } } } } } } }
