// All pages css
#container {
  position: relative;
  display: block;
  background-color: $white; }

a[href="no-link"] {
  pointer-events: none;
  opacity: 0.5; }

//Override menu for each media uqery
#menu-wrapper {
  background: linear-gradient(to right, rgba($dark-gray,0.75) 0%, rgba($dark-gray,0.75) 100%), url('../img/menu.jpg');
  background-position: center;
  background-size: cover; }

//---------------------------First Section--------------------------------------
.front-page-capa {
  position: relative;
  width: 100%;
  height: 640px;
  background-image: url('../img/startpage.jpg');
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, 0.3); }

  .wrapper-center {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    position: absolute;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;

    h2 {
      color: $black; }
    p {
      color: $black; } }

  .button {
    background-color: transparent;
    color: $black;
    padding: 1em 3em;
    border: 1px solid $black; } }

//---------------------------Second Section-------------------------------------
.front-page-links {
  position: relative;
  padding: 4em 0;
  .item {
    display: block;
    padding: 2em 0;
    text-align: center;
    color: $black;
    transform: scale(1);
    transition: transform 500ms ease;
    &:hover {
      transform: scale(1.1); }

    .icon {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center; }
    .biografia {
      background-image: url('../img/icons/biografia.svg'); }
    .cronologia {
      background-image: url('../img/icons/cronologia.svg'); }
    .video {
      background-image: url('../img/icons/documentario.svg'); }
    p {
      margin: 0 auto;
      padding-top: 0.5em;
      width: 120px;
      text-transform: lowercase;

      &::first-letter {
        text-transform: capitalize; } } } }

//----------------------------------Biografia-----------------------------------
.cronologia {
  .title {
    text-align: center; } }

//---------------------------News Section---------------------------------------
.front-page-news {
  position: relative;
  padding: 4em 0;

  .block-title {
    padding-left: 20px;
    text-align: center; }

  .news {
    display: block;
    position: relative;
    padding: 1em;
    height: 320px;
    color: $white;
    max-width: 320px;
    margin: 0 auto 20px;
    overflow: hidden;

    &:hover {
      .background-image {
        transform: scale(1.3); } }

    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom : 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transform: scale(1);
      transition: transform 25000ms ease-in-out; }

    .item-title {
      font-family: $body-font-family;
      font-weight: normal; }

    .text {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      background: linear-gradient(to bottom, rgba($black,0), $black);
      padding: 1em 0.5em; } } }


//-----------------------Bloquote Section---------------------------------------
.front-page-blockquote {
  position: relative;
  padding-top: 13em;
  padding-bottom: 2em;
  background-image: url('../img/landing.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, 0.4); }

  blockquote {
    position: relative;
    padding: 0.5625rem 1.75rem 0 1.75rem;
    color: $white;
    border-left: none;
    font-size: 1.5em;
    font-family: $header-font-family;

    p {
      color: $white; }

    cite {
      font-family: $body-font-family;
      position: relative;
      color: rgba($white, 0.9);
      font-size: 1rem; } } }


//--------------------------Notica Page-----------------------------------------
.page-header {
  position: relative;
  display: block;
  font-family: $header-font-family;
  .image {
    display: block;
    height: 400px;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.95) 100%); }

    .title {
      position: absolute;
      bottom: 1em;
      left: 0;
      right: 0;
      text-align: center;
      color: $white; } } }

.page-content {
  padding: 3em 0;
  .publisher {
    width: 280px;
    padding-top: 2em;
    margin: 0 auto;
    color: $dark-gray;
    text-align: center;
    .date {
      display: block; }
    .author {
      display: inline-block;
      border-top: 1px solid $primaryColor; } }

  share-button {
    display: block !important;
    text-align: center;
    border: 1px solid $black;
    color: $black;
    width: 200px;
    padding: 0.5em 1em;
    margin: 2em auto;
    background-color: transparent;
    border-radius: 0;
    font-weight: 300;
    font-family: $body-font-family;

    &:before {
      content: normal; }

    &:hover {
      background-color: transparent;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      transition: all 0.2s ease-in-out; } }

  .sb-social {
    li[class*='pinterest']:before,
    li[class*='pinterest']:after {
      background-image: url('../../resources/img/icons/svg/pinterest.svg') !important; }

    li[class*='twitter']:before,
    li[class*='twitter']:after {
      background-image: url('../../resources/img/icons/svg/twitter.svg') !important; }

    li[class*='facebook']:before,
    li[class*='facebook']:after {
      background-image: url('../../resources/img/icons/svg/facebook.svg') !important; }

    li[class*='googlePlus']:before,
    li[class*='googlePlus']:after {
      background-image: url('../../resources/img/icons/svg/googlePlus.svg') !important; } }

  .notice-content {
    padding-top: 1em;
    padding-bottom: 4em;

    p {
      color: $black;
      column-count: 1;
      column-gap: 10px;
      margin-bottom: 1em;

      &::first-letter {
        font-size: 2em;
        float: left;
        padding-top: 0.1em;
        padding-right: 0.05em;
        font-size: 5em;
        color: $primaryColor;
        line-height: 0.75em; } } }

  .entrevista-content {
    padding-top: 1em;
    padding-bottom: 4em;

    p {
      margin-bottom: 3em;

      strong {
        color: $primaryColor; } } } }

//--------------------------------News View-------------------------------------
.news-view {
  .news {
    display: block;
    position: relative;
    width: 100%;
    height: 500px;
    background-color: $black;

    &:hover {
      .background-image {
        opacity: 0.8;
        transition: opacity 300ms ease-out; } } }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.5;
    transition: opacity 300ms ease-out; }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: $white;
    text-align: center; } }


//--------------------------Orgãos Sociais Page---------------------------------
.orgaos-sociais-tree {
  .title {
    display: block;
    padding-top: 2em;
    padding-bottom: 1em;
    text-align: center; }

  .person {
    display: block;
    width: 120px;
    margin: 0 auto;
    text-align: center;

    .image {
      width: 100px;
      height: 100px;
      margin: 0.5em;
      border-radius: 100px;
      border: 0; }

    .name {
      padding: 0;
      margin: 0;
      font-family: $header-font-family;
      color: $dark-gray; }

    .cargo {
      display: block;
      margin: 0;
      padding: 0;
      margin-bottom: 1.5em;
      color: $secondary-color; } } }


//-----------------------------Fundação-----------------------------------------
.fundacao-links-wrapper {
  padding: 2em 0;
  .item {
    display: block;
    padding: 2em 0;
    margin: 0 auto;
    text-align: center;
    color: $dark-gray;
    transform: scale(1);
    transition: transform 500ms ease;
    &:hover {
      transform: scale(1.1); }
    .icon {
      margin: 0 auto;
      width: 100px;
      height: 100px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat; }
    .estatutos {
      background-image: url('../img/icons/estatutos.svg'); }
    .testamento {
      background-image: url('../img/icons/testamento.svg'); }
    .orgaos {
      background-image: url('../img/icons/orgaossociais.svg'); }
    .gestao {
      background-image: url('../img/icons/gestaodecontas.svg'); }
    .museu {
      background-image: url('../img/icons/museu.svg'); }
    .projectos {
      background-image: url('../img/icons/projectos.svg'); }
    .growth {
      background-image: url('../img/icons/growth.png'); }

    span {
      display: block;
      padding: 1em 0; } } }

//--------------------------Contact Page Content--------------------------------
.contact-content {
  .map {
    display: block;
    width: 100%;
    height: 600px; }

  .content {
    display: block;
    width: 100%;
    padding: .5em; } }

//--------------------------------Gestão e contas-------------------------------
.accordion {
  .accordion-title {
    font-weight: bold; }

  .icon::before {
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 5px;
    vertical-align: middle;
    background-image: url('../img/icons/file.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain; }

  .file {
    display: inline-block;
    width: calc(100% - 35px);
    vertical-align: middle; } }

//--------------------------Footer----------------------------------------------
footer {
  display: block;
  position: relative;
  background-color: $black;
  .menu-site-map {
    display: none; }

  .bottom-site-map {
    padding: 1em 0;
    border-top-color: rgba($white, 0.2);
    border-top-width: 1px;
    border-top-style: solid;
    color: $white;

    .footer-logo {
      width: 200px;
      margin: 0 auto;

      span {
        display: block;
        font-size: 0.7em;
        a {
          color: $white; } }

      img {
        margin-bottom: 5px;
        filter: grayscale(1);
        transition: filter 300ms linear; }

      &:hover {
        img {
          filter: grayscale(0); } } }

    .footer-more-links {
      display: none; } } }
