
//---------------------------First Section--------------------------------------
.front-page-capa {
  height: 800px; }

//-----------------------Bloquote Section---------------------------------------
.front-page-blockquote {
  padding-top: 20em;
  padding-bottom: 5em;

  blockquote {
    font-size: 1.8em; } }

//--------------------------Notice Page-----------------------------------------
.page-header {
  .image {
    height: 600px; } }

.page-content {
  .notice-content {
    p {
      column-count: 2; } } }

//--------------------------Contact Page Content--------------------------------
.contact-content {
  .map {
    width: 50%;
    float: left;
    height: 600px; }

  .content {
    width: 50%;
    float: right;
    padding: 2.5em; } }

//---------------------------News Section---------------------------------------
.front-page-news {
  .news {
    height: 200px;
    max-width: none; }

  .block-title {
    text-align: left; }

  .large-6 {
    .news {
      height: 420px; }

    .text {
      padding: 2em 1em; } } }
